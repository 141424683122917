@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Baloo+Bhaina+2&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e8f1f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: white !important;
}
/* only works on dev version? */
.PrivateTabIndicator-colorSecondary-73 {
  background-color: white !important;
}
/* for production build */
.MuiTabs-indicator {
  background-color: white !important;
}

.MuiMobileStepper-dotActive {
  background-color: white !important;
}

p {
  font-family: 'Baloo Bhaina 2', cursive;
}

/* Generated by Glyphter (http://www.glyphter.com) on  Tue Jan 21 2020*/
@font-face {
    font-family: 'Glyphter';
    src: url(/static/media/Glyphter.213a2df1.eot);
    src: url(/static/media/Glyphter.213a2df1.eot?#iefix) format('embedded-opentype'),
         url(/static/media/Glyphter.3c21082b.woff) format('woff'),
         url(/static/media/Glyphter.5630a2a4.ttf) format('truetype'),
         url(/static/media/Glyphter.9f85a5ac.svg#Glyphter) format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='glyphter-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.glyphter-spring-3:before{content:'\0041';}
.glyphter-material-ui:before{content:'\0042';}
.glyphter-xamarin:before{content:'\0047';}

/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
.MuiTypography-body1-132 {font-family: 'Roboto Mono', monospace !important;} */
